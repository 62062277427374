import React, { useEffect, useState, useRef } from "react";
import { useDataContext } from "../context/DataContext"; // Adjust the path as necessary
import Select from "react-select";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useAutoCompletes } from "../context/AutoCompleteContext"; // Import the hook

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: UploadDetails) => void;
}

interface UploadDetails {
  option: string;
  friendlyName: string;
  blobName: string;
  dealName?: string;
  year?: string;
  quarter?: string;
  file?: File;
}

const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { borrowers, context_error } = useDataContext();
  const { autoCompletes, latestQuarter } = useAutoCompletes(); // Use the hook to get autoCompletes
  const [option, setOption] = useState("");
  const [friendlyName, setFriendlyName] = useState("");
  const [blobName, setBlobName] = useState("");
  const [dealName, setDealName] = useState("");
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [dealNames, setDealNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const dealNameSelectRef = useRef<HTMLSelectElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);
  const quarterSelectRef = useRef<HTMLSelectElement>(null);
  const hiddenFriendlyNameInputRef = useRef<HTMLInputElement>(null);
  const hiddenOptionInputRef = useRef<HTMLInputElement>(null);

  const resetForm = () => {
    setOption("");
    setFriendlyName("");
    setBlobName("");
    setDealName("");
    setYear("");
    setQuarter("");
    setErrorMessage("");
    onClose();
  };

  const clearCustomValidity = (e: Event) => {
    (e.target as HTMLInputElement).setCustomValidity("");
  };

  useEffect(() => {
    if (hiddenFriendlyNameInputRef.current) {
      hiddenFriendlyNameInputRef.current.setCustomValidity("");
    }

    if (hiddenOptionInputRef.current) {
      hiddenOptionInputRef.current.setCustomValidity("");
    }

    if (yearInputRef.current) {
      yearInputRef.current.setCustomValidity("");
      yearInputRef.current.addEventListener("input", clearCustomValidity);
    }

    if (quarterSelectRef.current) {
      quarterSelectRef.current.setCustomValidity("");
      quarterSelectRef.current.addEventListener("input", clearCustomValidity);
    }

    if (dealNameSelectRef.current) {
      dealNameSelectRef.current.setCustomValidity("");
      dealNameSelectRef.current.addEventListener("input", clearCustomValidity);
    }

    return () => {
      hiddenFriendlyNameInputRef.current?.removeEventListener(
        "input",
        clearCustomValidity
      );
      hiddenOptionInputRef.current?.removeEventListener(
        "input",
        clearCustomValidity
      );
      yearInputRef.current?.removeEventListener("input", clearCustomValidity);
      quarterSelectRef.current?.removeEventListener(
        "input",
        clearCustomValidity
      );
      dealNameSelectRef.current?.removeEventListener(
        "input",
        clearCustomValidity
      );
    };
  }, [isOpen]);

  useEffect(() => {
    if (borrowers && friendlyName) {
      const borrowerData = borrowers[friendlyName];
      if (borrowerData) {
        setDealNames(Object.keys(borrowerData.nameIDMap));
        setBlobName(borrowerData.blobName); // Set blobName when friendlyName changes
        setDealName(""); // Reset dealName when friendlyName changes
      } else {
        setDealNames([]);
        setBlobName("");
        setDealName(""); // Reset dealName if no friendlyName is selected
      }
    }
  }, [borrowers, friendlyName]);

  useEffect(() => {
    if (friendlyName && option) {
      if (
        option === "ICM Memo" &&
        autoCompletes &&
        autoCompletes[friendlyName]
      ) {
        setDealName(autoCompletes[friendlyName]);
      } else if (option === "Quarterly Report" && latestQuarter) {
        const [currYear, currQuarter] = latestQuarter.split(".Q");
        setYear(currYear);
        setQuarter(currQuarter);
      }
    }
  }, [friendlyName, option, autoCompletes]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentYear = new Date().getFullYear();

    if (!option) {
      if (hiddenOptionInputRef.current) {
        hiddenOptionInputRef.current.setCustomValidity(
          "Please select an option."
        );
        hiddenOptionInputRef.current.reportValidity();
      }
      return;
    }
    hiddenOptionInputRef.current?.setCustomValidity("");

    if (hiddenFriendlyNameInputRef.current) {
      if (!friendlyName) {
        hiddenFriendlyNameInputRef.current.setCustomValidity(
          "Please select a borrower."
        );
        hiddenFriendlyNameInputRef.current.reportValidity();
        return;
      }
      hiddenFriendlyNameInputRef.current.setCustomValidity("");
    }

    if (option === "ICM Memo") {
      if (!dealName) {
        if (dealNameSelectRef.current) {
          dealNameSelectRef.current.setCustomValidity("Please select a deal.");
          dealNameSelectRef.current.reportValidity();
        }
        return;
      }
      dealNameSelectRef.current?.setCustomValidity("");
    }

    if (option === "Quarterly Report") {
      if (!year) {
        if (yearInputRef.current) {
          yearInputRef.current.setCustomValidity("Please enter a year.");
          yearInputRef.current.reportValidity();
        }
        return;
      } else if (parseInt(year, 10) > currentYear) {
        if (yearInputRef.current) {
          yearInputRef.current.setCustomValidity(
            "Year cannot be in the future."
          );
          yearInputRef.current.reportValidity();
        }
        return;
      }
      yearInputRef.current?.setCustomValidity("");

      if (!quarter) {
        if (quarterSelectRef.current) {
          quarterSelectRef.current.setCustomValidity(
            "Please select a quarter."
          );
          quarterSelectRef.current.reportValidity();
        }
        return;
      }
      quarterSelectRef.current?.setCustomValidity("");
    }

    onSubmit({ option, friendlyName, blobName, dealName, year, quarter });
    resetForm();
  };

  if (!isOpen) return null;

  const borrowerOptions = borrowers
    ? Object.keys(borrowers)
        .sort()
        .map((name) => ({ value: name, label: name }))
    : [];

  return (
    <>
      <Modal isOpen={isOpen} onClose={resetForm} isDismissable={false}>
        <ModalContent>
          <form onSubmit={handleSubmit} noValidate>
            <ModalHeader className="flex flex-col gap-1 text-xl font-bold text-black">
              Upload Details
            </ModalHeader>
            <ModalBody>
              {context_error && (
                <p className="text-red-500">Error: {context_error}</p>
              )}
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              <div className="mt-[-0.5rem] mb-[0.25rem]">
                <label className="block text-gray-700">
                  <input
                    type="radio"
                    name="option"
                    value="ICM Memo"
                    checked={option === "ICM Memo"}
                    onChange={(e) => setOption(e.target.value)}
                    className="mr-2"
                    required
                  />
                  IC Memo
                </label>
                <label className="block text-gray-700 mt-2">
                  <input
                    type="radio"
                    name="option"
                    value="Quarterly Report"
                    checked={option === "Quarterly Report"}
                    onChange={(e) => setOption(e.target.value)}
                    className="mr-2"
                    required
                  />
                  Quarterly Report
                </label>
              </div>

              <div>
                <label className="block text-gray-700 mb-2">Borrower:</label>
                <Select
                  value={borrowerOptions.find(
                    (option) => option.value === friendlyName
                  )}
                  onChange={(selectedOption) => {
                    setFriendlyName(selectedOption ? selectedOption.value : "");
                    if (hiddenFriendlyNameInputRef.current) {
                      hiddenFriendlyNameInputRef.current.setCustomValidity("");
                    }
                  }}
                  options={borrowerOptions}
                  classNamePrefix="react-select"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable
                />
                <input
                  type="text"
                  ref={hiddenFriendlyNameInputRef}
                  defaultValue="."
                  className="absolute -ml-20 left-0 mt-1 h-0.5 w-full opacity-0 pointer-events-none"
                  required
                />
              </div>
              {option === "ICM Memo" && dealNames.length > 0 && (
                <div className="mb-[0.25rem]">
                  <label className="block text-gray-700 mb-2">Deal Name:</label>
                  <select
                    ref={dealNameSelectRef}
                    value={dealName}
                    onChange={(e) => {
                      setDealName(e.target.value);
                      dealNameSelectRef.current?.setCustomValidity("");
                      // console.log("Deal name selected:", e.target.value);
                    }}
                    className="w-full px-3 py-2 border rounded"
                    required
                  >
                    <option value="" disabled>
                      Select a deal
                    </option>
                    {dealNames
                      .slice()
                      .reverse()
                      .map((deal, index) => (
                        <option key={index} value={deal}>
                          {deal}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {option === "Quarterly Report" && (
                <div className="w-1/2 mb-4 flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-gray-700 mb-2">
                      Portfolio Review Period:
                    </label>
                    <div className="w-full flex">
                      <select
                        ref={quarterSelectRef}
                        value={quarter}
                        onChange={(e) => {
                          setQuarter(e.target.value);
                          quarterSelectRef.current?.setCustomValidity("");
                        }}
                        className="w-1/2 px-3 py-2 border rounded"
                        required
                      >
                        <option value="" disabled></option>
                        <option value="1">Q1</option>
                        <option value="2">Q2</option>
                        <option value="3">Q3</option>
                        <option value="4">Q4</option>
                      </select>
                      <label className="block text-gray-700 mb-2"> </label>
                      <input
                        ref={yearInputRef}
                        type="number"
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value);
                          yearInputRef.current?.setCustomValidity("");
                        }}
                        max={new Date().getFullYear()}
                        className="w-1/2 px-3 py-2 border rounded"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                className="bg-gray-500 text-white"
                onPress={resetForm}
              >
                Cancel
              </Button>
              <Button
                size="md"
                className="bg-blue-500 text-white"
                type="submit"
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadModal;
