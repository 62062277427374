import React, { useRef, useState, useEffect } from "react";
import UploadModal from "../components/UploadModal";
import DragAndDrop from "../components/DragAndDrop";
import Banner from "../components/Banner";
import ActiveUploadCard from "../components/ActiveUploadCard";
import OverwriteModal from "../components/OverwriteModal";
import WaitModal from "../components/WaitModal";
import { useDataContext } from "../context/DataContext";
import { useAutoCompletes } from "../context/AutoCompleteContext";
import { sleep, decodeBase64 } from "../utils/utils";
import axiosInstance from "../utils/axiosSetup";
import { Button } from "@nextui-org/react";
import { useError } from "../context/ErrorContext";

interface UploadDetails {
  option: string;
  friendlyName: string;
  blobName: string;
  dealName?: string;
  year?: string;
  quarter?: string;
  file?: File;
}

const UploadPage: React.FC = () => {
  const { borrowers, context_error } = useDataContext();
  const { showError } = useError();
  const [file, setFile] = useState<File>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [overwriteModalOpen, setOverwriteModalOpen] = useState(false);
  const [waitModalState, setWaitModalState] = useState<
    "waiting" | "done" | "closed"
  >("closed");
  const [uploadDetails, setUploadDetails] = useState<UploadDetails | null>(
    null
  );
  const [existingFiles, setExistingFiles] = useState<
    { name: string; bytes: Uint8Array }[]
  >([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [uploadHistory, setUploadHistory] = useState<FormData[]>([]); // New state for upload history
  const ref = useRef<HTMLInputElement>(null);
  const { fetchAutoCompletes } = useAutoCompletes(); // Use the hook

  useEffect(() => {
    const timer = setTimeout(() => {
      if (context_error) {
        showError(
          "Error connecting to server. Please try reloading the page, and contact support if the error persists."
        );
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [context_error]);

  const handleFileChange = (file: File) => {
    if (file.type !== "application/pdf") {
      showError("Please upload a PDF.");
      resetFileInput();
      return;
    }
    setFile(file);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    resetFileInput();
  };

  const handleOverwriteModalClose = () => {
    setOverwriteModalOpen(false);
    resetFileInput();
  };

  const handleOverwriteConfirm = async () => {
    setOverwriteModalOpen(false);
    if (uploadDetails) {
      await handleSubmit(uploadDetails);
    }
  };

  const handleModalSubmit = (data: UploadDetails) => {
    const detailsWithFile = { ...data, file };
    setUploadDetails(detailsWithFile);
    checkFolderExists(detailsWithFile);
  };

  const resetFileInput = () => {
    setFileInputKey((prevKey) => prevKey + 1);
    setFile(undefined);
  };

  const checkFolderExists = async (details: UploadDetails) => {
    if (!details) return;
    try {
      const data = new FormData();
      data.set("option", details.option);
      data.set("blobName", details.blobName);
      if (details.option === "Quarterly Report") {
        data.set("year", details.year || "");
        data.set("quarter", details.quarter || "");
      }
      if (details.option === "ICM Memo" && borrowers && details.dealName) {
        const dealID =
          borrowers[details.friendlyName].nameIDMap[details.dealName];
        data.set("dealID", dealID || "");
      }
      console.log(`checking folder existence for ${details.blobName}`);
      const res = await axiosInstance.post(
        "/uploader/check_folder_exists",
        data
      );

      if (res.status !== 200) {
        throw new Error("Network response was not ok " + res.statusText);
      }

      const result = res.data;

      if (result.exists) {
        const decodedFiles = result.pdfs.map(
          (file: { name: string; b64_bytes: string }) => {
            return { name: file.name, bytes: decodeBase64(file.b64_bytes) };
          }
        );

        setExistingFiles(decodedFiles);
        setOverwriteModalOpen(true);
      } else {
        await handleSubmit(details);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      } else {
        console.error("An unknown error occurred");
      }
      showError(
        "An unknown error occured. Please try again, and talk to support if the issue persists."
      );
    }
  };

  const handleSubmit = async (details: UploadDetails | null) => {
    const fileToUpload = details?.file || null;

    if (!fileToUpload || !details) return;

    setWaitModalState("waiting"); // Open WaitModal
    try {
      const data = new FormData();
      data.set("file", fileToUpload);
      data.set("option", details.option);
      data.set("blobName", details.blobName);
      if (details.option === "Quarterly Report") {
        data.set("year", details.year || "");
        data.set("quarter", details.quarter || "");
      }
      if (details.option === "ICM Memo" && borrowers && details.dealName) {
        const dealID =
          borrowers[details.friendlyName].nameIDMap[details.dealName];
        data.set("dealID", dealID || "");
      }

      const res = await axiosInstance.post("/uploader/upload", data);

      if (res.status !== 200) {
        throw new Error(await res.data);
      }
      try {
        fetchAutoCompletes(); // Call fetchAutoCompletes after successful submission
      } catch (error) {
        console.error("Failed to fetch auto completes:", error);
      }
      if (data) {
        console.log(`updating uploaddetails with a ${data.get("option")}`);
        setUploadHistory((prevHistory) => [...prevHistory, data]);
      }
      resetFileInput();
      setUploadDetails(null);

      // Update upload history
      await sleep(500);
      setWaitModalState("done"); // Show Checkmark
      await sleep(1200);
      setWaitModalState("closed"); // Close WaitModal after 1500ms
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      } else {
        console.error("An unknown error occurred");
      }
      showError("File upload failed: an unknown error occured.");
      setWaitModalState("closed"); // Close WaitModal in case of error
    }
  };

  const removeUpload = (index: number) => {
    setUploadHistory(uploadHistory.filter((_, i) => i !== index));
  };

  const handleButtonClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <main className="relative flex flex-col items-center min-h-screen min-w-screen bg-gray-100 overflow-hidden">
      <div className="absolute top-0 w-full flex flex-col items-center">
        <Banner current="upload" />
        <div className="w-full mt-[0.5rem] ml-[1rem] flex justify-start">
          <ActiveUploadCard
            uploadHistory={uploadHistory}
            removeUpload={removeUpload}
          />
        </div>
      </div>
      <div className="w-screen flex-grow flex items-center justify-center pt-20 md:pt-32">
        <div className="p-8 bg-white rounded-lg shadow-lg w-full max-w-lg">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            <h1 className="text-3xl font-bold mb-6">Upload PDF</h1>
            <div
              className={context_error ? "opacity-50 pointer-events-none" : ""}
            >
              <DragAndDrop
                onFileChange={handleFileChange}
                onClick={() => showError("")}
                width="w-full"
                height="h-80"
                fileInputKey={fileInputKey}
              />
            </div>
            <div className="mt-6 flex items-center">
              <Button
                type="button"
                onClick={handleButtonClick}
                size="lg"
                radius="full"
                className={`text-base font-semibold ${
                  context_error
                    ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                    : "bg-primary text-white hover:bg-secondary"
                }`}
                disabled={!!context_error}
              >
                Choose File
              </Button>
              <input
                key={fileInputKey}
                type="file"
                name="file"
                onClick={() => showError("")}
                onChange={(e) =>
                  e.target.files && handleFileChange(e.target.files[0])
                }
                ref={ref}
                className="hidden"
              />
              <div className="ml-4 flex-1 overflow-hidden">
                <span className="text-base text-gray-500 overflow-hidden whitespace-nowrap overflow-ellipsis block">
                  {file ? file.name : "No file chosen"}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <UploadModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
      <OverwriteModal
        isOpen={overwriteModalOpen}
        onClose={handleOverwriteModalClose}
        onConfirm={handleOverwriteConfirm}
        existingFiles={existingFiles}
      />
      <WaitModal state={waitModalState} />
    </main>
  );
};

export default UploadPage;
