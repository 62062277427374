import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";

interface OverwriteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  existingFiles: { name: string; bytes: Uint8Array }[];
}

const OverwriteModal: React.FC<OverwriteModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  existingFiles,
}) => {
  const isMultiple = existingFiles.length > 1;
  if (!isOpen) return null;

  const handleFileClick = (file: { name: string; bytes: Uint8Array }) => {
    const blob = new Blob([file.bytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    const baseName = file.name.split("/").pop();
    a.href = url;
    a.download =
      `${baseName?.split(" ").slice(0, -2).join(" ")}.pdf` || "existing.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isDismissable={false}>
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1 text-xl font-bold mb-[-0.5rem]">
              Overwrite {isMultiple ? "Files" : "File"}
            </ModalHeader>
            <ModalBody>
              <p>
                The following file{isMultiple ? "s have" : " has"} already been
                uploaded.
              </p>
              <ul className="list-disc list-inside my-[0.75rem]">
                {existingFiles.map((file, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleFileClick(file)}
                      className="text-blue-500  w-10/12 truncate text-left overflow-hidden overflow-ellipsis ml-[-0.2rem]"
                    >
                      {file.name.split("/").slice(-1).join().trim()}
                    </button>
                  </li>
                ))}
              </ul>
              <p>Would you like to overwrite {isMultiple ? "them" : "it"}?</p>
            </ModalBody>
            <ModalFooter>
              <Button className="bg-gray-500 text-white" onPress={onClose}>
                Cancel
              </Button>
              <Button className="bg-blue-500 text-white " onPress={onConfirm}>
                Overwrite
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OverwriteModal;
