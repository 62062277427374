import axios from "axios";
import { API_URL, REDIRECT_URI } from "./constants";
import { msalInstance } from "..";
import { loginRequest } from "../authConfig";

const baseURL = REDIRECT_URI === "http://localhost:3000/" ? "/" : API_URL;
const axiosInstance = axios.create({ baseURL: baseURL });

axiosInstance.interceptors.request.use(async (config) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No account!");
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  if (config.headers) {
    config.headers.Authorization = response.accessToken
      ? `Bearer ${response.accessToken}`
      : "";
  } else {
    throw new Error("config.headers is undefined");
  }
  return config;
});

// Adding an interceptor to log requests after they are made
axiosInstance.interceptors.request.use(
  (config) => {
    console.log("Request made:", config.url);
    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    switch (error.response?.status) {
      case 404:
        throw Error(error.response.data?.message);
      case 401:
        window.location.href = "/";
        break;
      default:
        throw error;
    }
  }
);

export default axiosInstance;
