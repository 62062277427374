import React from "react";

interface ErrorProps {
  message: string;
  onClose: () => void;
}

const ErrorNotification: React.FC<ErrorProps> = ({ message, onClose }) => {
  if (!message) return null;
  return (
    <div
      className={`fixed top-[4rem] w-screen h-[4rem] bg-red-600 text-white text-lg py-4 px-6 flex justify-between shadow-md z-50`}
    >
      <span>{message}</span>
      <button onClick={onClose} className="text-xl font-bold self-start">
        &times;
      </button>
    </div>
  );
};

export default ErrorNotification;
