import React from "react";
import "./Checkmark.css";

interface AnimatedCheckmarkProps {
  color?: string;
  size?: string;
}

const AnimatedCheckmark: React.FC<AnimatedCheckmarkProps> = ({
  color = "#7ac142",
  size = "6rem",
}) => {
  const checkmarkStyle = {
    "--checkmark-color": color,
    "--checkmark-size": size,
  } as React.CSSProperties;

  return (
    <div className={`w-[${size}] h-[${size}]`} style={checkmarkStyle}>
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        style={checkmarkStyle}
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

export default AnimatedCheckmark;
