import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { DataProvider } from "./context/DataContext";
import { AutoCompletesProvider } from "./context/AutoCompleteContext";
import { ErrorProvider } from "./context/ErrorContext";
import { NextUIProvider } from "@nextui-org/react";
import UploadPage from "./pages/UploadPage";
import ReportPage from "./pages/ReportPage";
import NotFound from "./pages/NotFound"; // Import the NotFound component
import { msalInstance } from ".";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

const App: React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ ...loginRequest }}
      >
        <DataProvider>
          <AutoCompletesProvider>
            <ErrorProvider>
              <NextUIProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<Navigate to="/upload" />} />
                    <Route path="/upload" element={<UploadPage />} />
                    <Route path="/report" element={<ReportPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* Catch-all route */}
                  </Routes>
                </Router>
              </NextUIProvider>
            </ErrorProvider>
          </AutoCompletesProvider>
        </DataProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;
