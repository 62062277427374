// src/pages/NotFound.tsx
import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.message}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.link}>
        Go back to home page
      </Link>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    margin: "0 auto",
    padding: "20px",
    maxWidth: "600px",
  },
  title: {
    fontSize: "48px",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  message: {
    fontSize: "18px",
    color: "#555",
    marginBottom: "24px",
  },
  link: {
    fontSize: "18px",
    color: "#101c34",
    textDecoration: "none",
    padding: "10px 20px",
    border: "1px solid #101c34",
    borderRadius: "5px",
    transition: "background-color 0.3s, color 0.3s",
  },
};

export default NotFound;
