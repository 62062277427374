import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { ENV_NAME } from "../utils/constants";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react";

interface bannerProps {
  current: "upload" | "report";
}

const Banner: React.FC<bannerProps> = ({ current }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await instance
      .logoutRedirect()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        navigate("/");
      });
  };

  return (
    <Navbar
      maxWidth="full"
      className="bg-primary flex justify-between items-center"
    >
      <NavbarContent>
        <img src={"/images/bannerLogo.svg"} alt="logo" className="h-[4rem]" />
        <NavbarBrand>
          <p className="text-white text-lg ml-[-0.5rem]">
            Owl Insights Document Management {ENV_NAME}
          </p>
        </NavbarBrand>
      </NavbarContent>
      <div className="flex bg-transparent">
        <NavbarContent>
          <NavbarItem>
            <Link
              href={current == "upload" ? undefined : "/upload"}
              className="bg-transparent text-white text-lg mr-[1.5rem]"
              underline={current == "upload" ? "always" : "hover"}
              size="sm"
            >
              Upload
            </Link>
            <Link
              href={current == "report" ? undefined : "/report"}
              className="bg-transparent text-white text-lg mr-[0.6rem]"
              underline={current == "report" ? "always" : "hover"}
              size="sm"
            >
              Report
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Button
              isIconOnly
              className="bg-transparent"
              onPress={handleLogout}
            >
              <img
                src={"/images/logout.svg"}
                alt="logout"
                className="h-[1.75rem]"
              />
            </Button>
          </NavbarItem>
        </NavbarContent>
      </div>
    </Navbar>
  );
};

export default Banner;
