import React, { useRef, useState } from "react";

interface DragAndDropProps {
  onFileChange: (file: File) => void;
  onClick?: () => void;
  width?: string;
  height?: string;
  fileInputKey: number; // Add fileInputKey as a prop
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  onFileChange,
  onClick,
  width = "w-full",
  height = "h-64",
  fileInputKey,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [dragging, setDragging] = useState(false);

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChange(e.dataTransfer.files[0]);
    }
  };

  const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0]);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`relative border-4 border-dashed border-gray-300 rounded-lg p-4 flex items-center justify-center ${
        dragging ? "opacity-50" : "opacity-100"
      } ${width} ${height}`}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={onDrop}
      onClick={handleClick}
    >
      <div className="text-center">
        <svg
          className="w-24 h-24 mx-auto mb-4"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="#00244f"
          stroke="#00244f"
          strokeWidth="0.00024"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                fillRule="nonzero"
                d="M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z"
              ></path>
            </g>
          </g>
        </svg>
        <p className="text-gray-600">Browse or Drop File</p>
      </div>
      <input
        key={fileInputKey} // Use the key prop here
        type="file"
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        onChange={onFileChangeHandler}
      />
    </div>
  );
};

export default DragAndDrop;
