import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardBody, Divider, Button } from "@nextui-org/react";
import { QuestionIcon } from "../assets/QuestionIcon";
import { TailSpin } from "react-loader-spinner";
import axiosInstance from "../utils/axiosSetup";

interface ActiveUploadCardProps {
  uploadHistory?: FormData[];
  removeUpload: (index: number) => void;
}

const ActiveUploadCard: React.FC<ActiveUploadCardProps> = ({
  uploadHistory = [], // Use default parameter instead of defaultProps
  removeUpload,
}) => {
  const [processedItems, setProcessedItems] = useState<{
    [key: string]: "loading" | "success" | "error";
  }>({});
  const [showInfoCard, setShowInfoCard] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const checkIngestionStatus = async (formData: FormData) => {
      const file = formData.get("file") as File;
      const fileName = file?.name || "";

      try {
        console.log("Checking status for:", fileName); // Log for debugging

        const response = await axiosInstance.get("/uploader/ingestion_status", {
          params: {
            fileName: fileName,
            blobName: formData.get("blobName") as string,
            option: formData.get("option") as string,
            dealID: formData.get("dealID") as string,
            year: formData.get("year") as string,
            quarter: formData.get("quarter") as string,
          },
        });

        const { ingested_status } = response.data;
        setProcessedItems((prev) => ({
          ...prev,
          [fileName]: ingested_status ? "success" : "error",
        }));
      } catch (error) {
        console.error("Error checking ingestion status:", error);
        setProcessedItems((prev) => ({
          ...prev,
          [fileName]: "error",
        }));
      }
    };

    const newItems = uploadHistory.filter(
      (formData) => !processedItems[(formData.get("file") as File)?.name || ""]
    );

    newItems.forEach((formData) => {
      const fileName = (formData.get("file") as File)?.name || "";
      setProcessedItems((prev) => ({ ...prev, [fileName]: "loading" }));
      checkIngestionStatus(formData);
    });
  }, [uploadHistory]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [uploadHistory]);

  const toggleInfoCard = () => {
    setShowInfoCard((prev) => !prev);
  };

  return (
    <div className="relative z-10 max-w-full md:max-w-[calc(100%-2rem)]">
      <Card className="w-[25rem] max-h-[20rem]">
        <CardHeader className="justify-between">
          <div className="flex gap-5">
            <div className="flex flex-col gap-1 items-start justify-center">
              <h4 className="text-lg font-semibold leading-none text-default-600">
                Active Uploads
              </h4>
            </div>
          </div>
          <Button
            className="min-w-[1rem] w-[1.5rem] h-[1.5rem]"
            radius="full"
            isIconOnly
            onPress={toggleInfoCard}
          >
            <QuestionIcon />
          </Button>
        </CardHeader>
        <Divider />
        <CardBody className="px-3 py-0 h-[10rem] text-md text-default-400">
          {uploadHistory.length === 0 ? (
            <p className="mt-[0.5rem]">
              Nothing here yet. Upload a PDF to track its status!
            </p>
          ) : (
            <ul ref={listRef} className="overflow-y-auto max-h-[10rem]">
              {uploadHistory.map((formData, index) => {
                const file = formData.get("file") as File;
                const fileName = file?.name || "";
                return (
                  <div
                    className="flex justify-between items-center m-4 h-[2rem]"
                    key={index}
                  >
                    <li className="flex-1 text-md overflow-hidden text-ellipsis whitespace-nowrap max-w-[14rem] inline block">
                      {fileName}
                    </li>
                    {processedItems[fileName] === "loading" ? (
                      <TailSpin color="grey" width="24" height="24" />
                    ) : processedItems[fileName] === "success" ? (
                      <img
                        src="/images/ingestSuccess.svg"
                        alt="Success"
                        width="24"
                        height="24"
                      />
                    ) : (
                      <img
                        src="/images/ingestFail.svg"
                        alt="Failure"
                        width="24"
                        height="24"
                      />
                    )}
                  </div>
                );
              })}
            </ul>
          )}
        </CardBody>
      </Card>
      {showInfoCard && (
        <Card className="w-[20rem] max-h-[15rem] mt-[1rem]">
          <CardHeader className="justify-between">
            <h4 className="text-lg font-semibold leading-none text-default-600">
              What is this?
            </h4>
            <Button
              className="min-w-[1rem] w-[1.5rem] h-[1.5rem] font-bold"
              radius="full"
              isIconOnly
              onPress={toggleInfoCard}
            >
              X
            </Button>
          </CardHeader>
          <Divider />
          <CardBody className="px-3 py-2 text-md text-default-400 opacity-100">
            Uploads that appear here are being processed by our natural language
            libraries. Monitor their progress here - if you see a green check,
            expect information to be accessible in live chats within one
            business day.
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default ActiveUploadCard;
