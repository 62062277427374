if (
  !process.env.REACT_APP_CLIENT_ID ||
  !process.env.REACT_APP_AUTHORITY ||
  !process.env.REACT_APP_API_SCOPE ||
  !process.env.REACT_APP_REDIRECT_URI ||
  !process.env.REACT_APP_API_URL
) {
  throw new Error("Missing environment variables");
}

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const AUTHORITY = process.env.REACT_APP_AUTHORITY;
export const API_SCOPE = process.env.REACT_APP_API_SCOPE;
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
export const API_URL = process.env.REACT_APP_API_URL;
export const ENV_NAME = process.env.REACT_APP_ENV_NAME;
