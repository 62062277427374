import React from "react";
import { Modal, ModalContent, ModalBody, ModalHeader } from "@nextui-org/react";
import { BallTriangle } from "react-loader-spinner";
import AnimatedCheckmark from "../assets/AnimatedCheckmark";

interface WaitModalProps {
  state: "waiting" | "done" | "closed";
}

const WaitModal: React.FC<WaitModalProps> = ({ state }) => {
  return (
    <Modal isOpen={state !== "closed"} isDismissable={false}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1 text-xl font-bold text-black mb-3">
          Upload Details
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-2xl mb-5">Uploading...</p>
            {state === "waiting" && (
              <BallTriangle height={"4rem"} color={"#087ccc"} />
            )}
            {state === "done" && (
              <AnimatedCheckmark size="6rem" color="#087ccc" />
            )}
          </div>
          <div className="h-[4rem]" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WaitModal;
