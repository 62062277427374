import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axiosInstance from "../utils/axiosSetup";
import { useMsal } from "@azure/msal-react";

interface AutoCompletes {
  [key: string]: string;
}

interface LatestQuarter {
  latest_quarter: string;
}

interface AutoCompletesContextType {
  autoCompletes: AutoCompletes | null;
  latestQuarter: string | null;
  fetchAutoCompletes: () => Promise<void>;
}

const AutoCompletesContext = createContext<
  AutoCompletesContextType | undefined
>(undefined);

export const AutoCompletesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { accounts } = useMsal();
  const [autoCompletes, setAutoCompletes] = useState<AutoCompletes>({});
  const [latestQuarter, setLatestQuarter] = useState<string | null>(null);

  const fetchAutoCompletes = async () => {
    if (!accounts || accounts.length == 0) return;
    try {
      const response = await axiosInstance.get("/uploader/autocompletes");
      if (response.status !== 200) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const data = response.data;
      // console.log(data);
      setAutoCompletes(data.autocompletes);
      setLatestQuarter(data.latest_quarter);
    } catch (e) {
      if (e instanceof Error) {
        console.error("Failed to fetch autocompletes:", e.message);
      }
    }
  };

  useEffect(() => {
    fetchAutoCompletes();
  }, [accounts]);

  return (
    <AutoCompletesContext.Provider
      value={{ autoCompletes, latestQuarter, fetchAutoCompletes }}
    >
      {children}
    </AutoCompletesContext.Provider>
  );
};

export const useAutoCompletes = (): AutoCompletesContextType => {
  const context = useContext(AutoCompletesContext);
  if (!context) {
    throw new Error(
      "useAutoCompletes must be used within an AutoCompletesProvider"
    );
  }
  return context;
};
