import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import axiosInstance from "../utils/axiosSetup";
import { useMsal } from "@azure/msal-react";

interface BorrowerData {
  blobName: string;
  nameIDMap: { [key: string]: string };
}

interface DataContextProps {
  borrowers: { [key: string]: BorrowerData } | null;
  context_error: string | null;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [borrowers, setBorrowers] = useState<{
    [key: string]: BorrowerData;
  } | null>(null);
  const [context_error, setError] = useState<string | null>("");
  const { accounts } = useMsal();

  const fetchBorrowersWithDeals = async () => {
    if (!accounts || accounts.length === 0) return;
    try {
      // const startTime = Date.now();
      // console.log(`fetch DC start time: ${startTime}`);
      const response = await axiosInstance.get<{
        [key: string]: [
          string,
          {
            CloseDate: string;
            DealName: string;
            isOriginal: boolean;
            desiID: string;
          }[]
        ];
      }>("/document/borrowers_and_deals", {
        headers: {
          Accept: "application/json",
        },
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      // console.log(`fetch DC duration: ${Date.now() - startTime}`);
      const data = response.data;
      const transformedData: { [key: string]: BorrowerData } = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const nameIDMap: { [key: string]: string } = {};
          data[key][1].forEach((item) => {
            nameIDMap[item.DealName] = item.isOriginal
              ? `Original ${item.desiID}`
              : `Addon ${item.desiID}`;
          });
          transformedData[key] = {
            blobName: data[key][0],
            nameIDMap,
          };
          setError(null);
        }
      }
      setBorrowers(transformedData);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    fetchBorrowersWithDeals();
  }, [accounts]);

  return (
    <DataContext.Provider value={{ borrowers, context_error }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
